import React from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Hover from "../components/hover";
import Footer from "../components/footer";
import VideoMockup from "../components/videoMockup";
import Container from "../components/./container";
import AnimatedText from '../components/animatedText';
import ArrowDown from "../components/arrowDown";
import Seo from "../components/seo"

const Wrapper = styled.div`
.flux > div {
    padding-top: 100px;
}

.flux > div:first-child {
    padding-top: 20vh;
    padding-bottom: 5vh;
}

.flux > div:nth-child(2){
    padding-top: 0;
}

h1{
    text-align:left;
    text-transform: uppercase;
    letter-spacing: .5rem;
    font-size: 5rem;
    padding-bottom:2rem;
}

.wecreate2,
.wecreate3{
    position:absolute;
    top:0;
}

@media(max-width:512px){
    h1{
    letter-spacing: .3rem;
    font-size: 10vw;
}
}
`


// markup
const PhygitalExperiences = () => {

    return (
        <>
            <Layout>
                <Seo title="Phygital Experiences - Vertual" />
                <Hover type="">
                    <main data-scroll data-scroll-id="update">
                        <ArrowDown />
                        <Wrapper>
                            <div className="flux">
                                <Container block position="intro" reversed direction="column">
                                    <AnimatedText tag="h1">PHYGITAL EXPERIENCES</AnimatedText>
                                    <AnimatedText tag="p">
                                        We create a new phygital experience powered by Augmented Reality
                                    </AnimatedText>
                                </Container>
                                <VideoMockup video={"phygitalexperiences"} fullpage />
                                <Container vh>
                                    <div className="mockupVideo">
                                        <VideoMockup video={"iphonephygitalexperiences"} flexend />
                                    </div>
                                    <div className="description">
                                        <AnimatedText tag="h2">The bridge between a physical store and online shopping</AnimatedText>
                                        <AnimatedText tag="p">Accessible through any browser, our AR solutions enable personalized product previews and the interaction with elements placed in a physical environment.</AnimatedText>
                                    </div>
                                </Container>
                                <Footer page="popupstore" />
                            </div>
                        </Wrapper>
                    </main>
                </Hover>
            </Layout>
        </>
    )
}

export default PhygitalExperiences


