import React from 'react';
import Arrow from '../assets/arrow-down.svg'
import styled, { keyframes } from 'styled-components'

const wobble = keyframes`
  0% {
    -webkit-transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(7px);
  }

  100% {
    -webkit-transform: translateY(0px);
    }
`;

const StyledLogo = styled.div`

position:absolute;
animation: ${wobble} 1s linear infinite;
svg{
    height: 2.5rem;
    position: relative;
    top: calc(var(--app-height) - 12vh);
    width: 100vw;
    fill: #fff;
    stroke:#fff;
    stroke-miterlimit:10;
    stroke-width:15px;
    justify-content:center;
    display:flex;
    ${props => props.services ? "opacity:1;" : "opacity:0;"};
    transition: .5s;
}

.is-inview svg{ 
    opacity:1;
}


@media (max-width:512px){
    svg{
    height: 2rem;
    top: calc(var(--app-height) - 9vh);
}
}
`;

const ArrowDown = (props) => {

    return <StyledLogo services={props.services}> <div data-scroll data-scroll-repeat data-scroll-offset="250"><Arrow /></div></StyledLogo>
};

export default ArrowDown;