import React from 'react';

const animatedText = (props) => {
    let text = ""
    switch (props.tag) {
        case "h2": text = <h2 class="o-title" data-scroll data-scroll-offset="150">
            <span class="o-title_line"> <span>{props.children}</span></span>
        </h2>
            break;
        case "paragraph": text = <h2 class="o-title paragraph" data-scroll data-scroll-offset="150">
            <span class="o-title_line"> <span>{props.children}</span></span>
        </h2>
            break;
        case "h3": text = <h3 class="o-title" data-scroll data-scroll-offset="150">
            <span class="o-title_line"> <span>{props.children}</span></span>
        </h3>
            break;
        case "h4": text = <h4 class="o-title underline" data-scroll data-scroll-offset="150">
            <span class="o-title_line"> <span>{props.children}</span></span>
        </h4>
            break;
        case "p": text = <p class="o-title" data-scroll data-scroll-offset="150">
            <span class="o-title_line"> <span>{props.children}</span></span>
        </p>
            break;
        case "h1": text = <h1 class="o-title" data-scroll data-scroll-offset="150">
            <span class="o-title_line"> <span>{props.children}</span></span>
        </h1>
            break;
        case "h1-main": text = <h1 class="o-title o-title-main" data-scroll data-scroll-offset="150">
            <span class="o-title_line"> <span>{props.children}</span></span>
        </h1>
            break;
        default:
            break;
    }
    return text
};

export default animatedText;